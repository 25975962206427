import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import * as JSZip from 'jszip';
import { isMoment, Moment } from 'moment';
import { box, unbox } from 'ngrx-forms';
import { forkJoin, Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AccountDeputyDtoModel } from '../../core/models/account-deputy-dto.model';
import { AccountFormMappingDtoModel } from '../../core/models/account-form-mapping-dto.model';
import { Article } from '../../core/models/article.model';
import { CateringOrderData } from '../../core/models/catering-order-data.model';
import { FileUploadModel } from '../../core/models/file-upload.model';
import { FormConfigIdentityModel } from '../../core/models/form-config-identity.model';
import { FuvDataResponseModel } from '../../core/models/fuv-data.model';
import { LocationInfo } from '../../core/models/location-info.model';
import { NetRegionModel } from '../../core/models/net-region.model';
import { ProcessQueueModel } from '../../core/models/process-queue.model';
import { Room } from '../../core/models/room.model';
import { AppConfigService } from '../../core/services/app-config.service';
import { CreditorInfoModel } from '../../forms-lib/models/creditor-info.model';
import { ProductGroupInfoModel } from '../../forms-lib/models/product-group-info.model';
import { SapInfoModel } from '../../forms-lib/models/sap-info.model';
import { FormA1DtoModel } from '../../forms/form-a1/models/form-a1-dto.model';
import { FormBaseDtoModel } from '../../forms/form-base/models/form-base-dto.model';
import { FormK3OrderData } from '../../forms/form-k3/models/form-k3-order-data.model';
import { VoucherData } from '../../forms/form-u1/models/form-u1-voucher-data.model';
import { VoucherEntry } from '../../forms/form-u1/models/form-u1-voucher-entry.model';
import { CategoryDataDto } from '../models/category-data-dto.model';
import { CategoryInfoModel } from '../models/category-info.model';
import { CompanyDataDtoModel } from '../models/company-data-dto.model';
import { ConfigDtoModel, ConfigDtoRestModel, ConfigurationItems } from '../models/config-dto.model';
import { CumulativeDashboardData, DashboardStateCountType, DashboardThroughputType } from '../models/dashboard.model';
import { EasyFormModel } from '../models/easy-form.model';
import { FaqElementModel } from '../models/faq-tree.model';
import { FavoriteModel } from '../models/favorite.model';
import { FormDtoModel } from '../models/form-dto.model';
import { GlobalConfigDtoModel } from '../models/global-config-dto.model';
import { PageableModel } from '../models/pageable.model';
import { ProposalHeadModel } from '../models/proposal-head.model';
import { SortFilterPageModel } from '../models/sort-filter-page.model';
import { SystemMessage } from '../models/system-message.model';
import { TableColumnConfigModel } from '../models/table-column-config.model';
import { TableFilterConfigModel } from '../models/table-filter-config.model';
import { TemplateModel } from '../models/template.model';
import { TileModel } from '../models/tile.model';
import TranslationMapModel from '../models/translation-map.model';
import { UserDataDtoModel } from '../models/user-data-dto.model';
import { UserDtoModel } from '../models/user-dto.model';
import { UserSearchModel } from '../models/user-search.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
  ) {
  }

  get base() {
    return this.appConfigService.config.backendBase + '/api';
  }

  public static downloadResponse(response) {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    const blob = new Blob([response.body], { type: response.headers.get('content-type') });
    ApiService.browserDownloadBlob(blob, filename);
  }

  public static browserDownloadJSONObjectZIP(ob: object, fileName: string, modifyFn = x => x) {
    const zip = new JSZip();
    zip.file(`${fileName}.json`, modifyFn(JSON.stringify(ob, null, 2)));
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 6 },
    }).then((content) => {
      this.browserDownloadBlob(content, `${fileName}.zip`);
    });
  }

  public static browserDownloadJSONObject(ob: object, fileName: string, modifyFn = x => x) {
    const blob = new Blob([modifyFn(JSON.stringify(ob, null, 2))], { type: 'application/json' });
    this.browserDownloadBlob(blob, `${fileName}.json`);
  }

  public static browserDownloadBlob(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    this.downloadFromDataUrl(fileName, url);
  }

  public static downloadFromDataUrl(fileName: string, url: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  private readonly doForEachSync = (urls: string[], method: Function = this.http.get) => {
    const responses = [];
    for (const url of urls) {
      responses.push(method(url));
    }
    return forkJoin(responses);
  };

  private readonly formatURLParam = (d: string | undefined, name: string) => d ?
    `&${name}=` + encodeURIComponent(d) : '';

  getActuatorInfo = () => this.http.get(`${this.base}/actuator/info`).pipe(
    share(),
  );

  getLoginState = () => this.http.get(`${this.base}/session/isloggedin`);

  getCurrentUser = (): Observable<UserDtoModel> => this.http.get<any>(`${this.base}/user`).pipe(
    // set default values '' if null
    map((response) => (
      {
        ...response,
        data: Object.keys(response.data)
          .reduce((sum, key) => (
            {
              ...sum,
              [key]: response.data[key] || null,
            } as UserDataDtoModel
          ), {}),
      } as UserDtoModel
    )),
  );

  getTranslations = (language: string) => this.http.get<TranslationMapModel>(`${this.base}/translation/${language}?keys=`);

  getUserFind = (query: string) => this.http.get<UserSearchModel[]>(`${this.base}/user/find?q=${encodeURIComponent(query)}`);

  getAllCompanies = () => this.http.get<CompanyDataDtoModel[]>(`${this.base}/company`);

  getAllCategories = () => this.http.get<CategoryDataDto[]>(`${this.base}/form/categories`);

  getGlobalConfig = () => this.http.get<GlobalConfigDtoModel>(`${this.base}/config/global`);

  postGlobalConfig = (config: GlobalConfigDtoModel) => this.http.post<GlobalConfigDtoModel>(`${this.base}/config`, config);

  getSystemMessage = () => this.http.get<SystemMessage>(`${this.base}/info/system`);

  getSystemMessageAdmin = () => this.http.get<SystemMessage>(`${this.base}/info/system/admin`);

  postSystemMessage = (systemMessage) => this.http.post<SystemMessage>(`${this.base}/info/system`, systemMessage);

  postCompany = (value: CompanyDataDtoModel) => this.http.post<CompanyDataDtoModel>(`${this.base}/company`, value);

  deleteCompany = (value: CompanyDataDtoModel) => this.http.delete<any>(`${this.base}/company/${value.id}`);

  deleteConfigItem = (id: string) => this.http.delete<any>(`${this.base}/config/item/${id}`);

  deleteCostCenter = (id: string) => this.http.delete<any>(`${this.base}/user/costcenter/${id}`);

  deleteArranger = (id: string) => this.http.delete<any>(`${this.base}/user/defaultarranger/${id}`);

  getEasyForms = () => this.http.get<EasyFormModel[]>(`${this.base}/form`).pipe(
    map((result) => result.sort((a, b) => (a && a.identifier && a.identifier.localeCompare(b && b.identifier)))),
  );

  getEasyFormConfig = <T extends ConfigurationItems>(formId: string, companyShort: string): Observable<ConfigDtoModel<T>> => {
    return this.http.get<ConfigDtoRestModel<T>>(
      this.base + `/config?formId=${formId}&companyShort=${encodeURIComponent(companyShort)}`,
    ).pipe(
      map(config => (!!config ? { ...config, parent: box(config.parent) } : undefined)),
    );
  };

  deleteEasyFormConfigs = (configs: Partial<FormConfigIdentityModel>[]) => this.doForEachSync(
    configs.map(c => `${this.base}/config?formId=${c.formId || ''}&companyId=${c.companyId || ''}`),
    (x) => this.http.delete(x),
  );

  postProcessCache = (data: FormBaseDtoModel) => this.http.post<FormBaseDtoModel>(`${this.base}/process/cache`, data);

  postProcessSave = (data: FormBaseDtoModel) => this.http.post<FormBaseDtoModel>(`${this.base}/process`, data);

  postProcessSend = (publicId: string) => this.http.post(`${this.base}/process/send/${publicId}`, {});

  postProcessRecall(publicId: string) {
    return this.http.post<void>(`${this.base}/process/recall`, publicId);
  }

  postProcessCancelByCaterer = (publicId: string, message: string) => this.http.post<never>(`${this.base}/catering/cancel`, {
    publicId,
    message,
  });

  getProcessById = <T = any>(id: string) => this.http.get<T>(`${this.base}/process/${id}`);

  getUserDataById = (userId: string) => this.http.get<any>(`${this.base}/user/data/${userId}`);

  postTemplateSave = (proposal: any, templateName: string) => this.http.post<FormA1DtoModel>(
    `${this.base}/template/${templateName}`,
    proposal,
  );

  postProcessApprove = (publicId: string) => this.http.post<any>(`${this.base}/process/approval`, {
    publicId,
    state: 'APPROVED',
  });

  postProcessReject = (publicId: string, message: string) => this.http.post<any>(`${this.base}/process/approval`, {
    publicId,
    message,
    state: 'DECLINED',
  });

  postCateringReject = (publicId: string, message: string) => this.http.post<any>(`${this.base}/catering/decline`, {
    publicId,
    message,
  });

  postCateringApprove = (form: any) => this.http.post<any>(`${this.base}/catering/approve`, form);

  postCateringComplete = (form: any) => this.http.post<any>(`${this.base}/catering/complete`, form);

  postEasyFormConfig = (value: ConfigDtoModel<any>) => this.http.post(`${this.base}/config`, {
    ...value,
    parent: unbox(value.parent),
  });

  getEasyFormConfigs = (formIdentifier: string) => this.http.get<ConfigDtoModel<ConfigurationItems>[]>(
    `${this.base}/config/form/${formIdentifier || ''}`,
  ).pipe(
    map(response => response || []),
  );

  getUserFindById = (id: string) => this.http.get<UserSearchModel>(`${this.base}/user/find/${id}`);

  getAllFavorites() {
    return this.http.get<FormDtoModel[]>(`${this.base}/form/favorite`);
  }

  postFormFavorite(data: FavoriteModel) {
    return this.http.post(`${this.base}/form/favorite`, data);
  }

  savFavoriteOrder(data: TileModel[]) {
    return this.http.post<any>(`${this.base}/form/favorite/order`, data);
  }

  deleteFormFavorite(identifier: string) {
    return this.http.delete<any>(`${this.base}/form/favorite/${identifier}`);
  }

  getAllTemplates() {
    return this.http.get<FormA1DtoModel[]>(`${this.base}/template`);
  }

  getDeputyTemplates() {
    return this.http.get<Dictionary<FormBaseDtoModel[]>>(`${this.base}/template/deputy`);
  }

  postTemplate(data: TemplateModel) {
    return this.http.post(`${this.base}/template`, data);
  }

  deleteTemplate(identifier: string, templateName: string) {
    return this.http.delete<any>(`${this.base}/template/${templateName}/${identifier}`);
  }

  saveTemplateOrder(data: TileModel[]) {
    return this.http.post<any>(`${this.base}/template/order`, data);
  }

  postUserData(data: UserDataDtoModel) {
    return this.http.post<UserDataDtoModel>(`${this.base}/user/data/save`, data);
  }

  getUserTableColumnConfig(tableName: string) {
    return this.http.get<TableColumnConfigModel[]>(`${this.base}/user/table/config/column/${tableName}`);
  }

  getUserTableFilterConfig(tableName: string) {
    return this.http.get<TableFilterConfigModel[]>(`${this.base}/user/table/config/filter/${tableName}`);
  }

  getTablePage(config: SortFilterPageModel, path: string): Observable<PageableModel<ProposalHeadModel>> {
    const { sort, filter } = this.buildSortAndFilter(config);

    const url = `${this.base}/${path}?`
      + `selection=${encodeURIComponent(config.selection)}`
      + `&page=${encodeURIComponent(config.page.number)}`
      + `&size=${config.page.size}${sort}${filter}`;
    return this.http.get<PageableModel<ProposalHeadModel>>(url);
  }

  getTableExport(config: SortFilterPageModel, selectedLanguage, path: string): Observable<any> {
    const { sort, filter } = this.buildSortAndFilter(config);
    const url = `${this.base}/${path}/export?selection=${encodeURIComponent(config.selection)}${sort}${filter}&lang=${selectedLanguage}`;
    return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
      map(response => ApiService.downloadResponse(response)),
    );
  }

  private buildSortAndFilter(config: SortFilterPageModel) {
    let filter = '';
    let sort = '';

    if (config.filter) {
      filter = Object.entries(config.filter)
        .filter(entry => (typeof entry[1] === 'string')
          ? entry[1] && entry[1].length > 0
          : entry[1] !== undefined && entry[1] !== null)
        .map(entry => {
          if (isMoment(entry[1])) {
            const moment = entry[1] as Moment;
            return `${entry[0]}:${moment.toISOString()}`;
          } else {
            return `${entry[0]}:${entry[1]}`;
          }
        })
        .join('|');
      filter = `&filter=${encodeURIComponent(filter)}`;
    }

    if (config.sort) {
      sort = `${config.sort.prop},${config.sort.dir}`;
      sort = `&sort=${encodeURIComponent(sort)}`;
    }
    return { sort, filter };
  }

  postUserTableColumnConfig(tableConfig: {
    columnConfig: TableColumnConfigModel[],
    tableName: string
  }): Observable<TableColumnConfigModel[]> {
    tableConfig.columnConfig.forEach((config, index) => {
      config.position = index;
      config.name = tableConfig.tableName;
    });
    return this.http.post<TableColumnConfigModel[]>(
      `${this.base}/user/table/config/column/${tableConfig.tableName}`,
      tableConfig.columnConfig,
    );
  }

  postUserTableFilterConfig(tableConfig: {
    filterConfig: TableFilterConfigModel[],
    tableName: string
  }): Observable<TableFilterConfigModel[]> {
    tableConfig.filterConfig.forEach((config) => {
      config.name = tableConfig.tableName;
    });
    return this.http.post<TableFilterConfigModel[]>(
      `${this.base}/user/table/config/filter/${tableConfig.tableName}`,
      tableConfig.filterConfig,
    );
  }

  getWaitingAdvancedProposal(): Observable<ProposalHeadModel[]> {
    return this.http.get<ProposalHeadModel[]>(`${this.base}/deduction/waiting`);
  }


  deleteProcess(publicId: string) {
    return this.http.delete<void>(`${this.base}/process/${publicId}`);
  }

  getDeputy(userId: string) {
    return this.http.get<AccountDeputyDtoModel[]>(`${this.base}/deputy${userId ? `?user=${userId}` : ''}`);
  }

  postDeputy(data: AccountDeputyDtoModel[], userId = '') {
    return this.http.post<AccountDeputyDtoModel[]>(`${this.base}/deputy/${userId}`, data);
  }

  getValidateAccounting(selectedCompany: CompanyDataDtoModel, input: string): Observable<SapInfoModel[]> {
    if (selectedCompany?.shortName && input) {
      return this.http.get<SapInfoModel[]>(`${this.base}/validate/accounting/${selectedCompany.shortName}/${input}`);
    } else {
      return of([]);
    }
  }

  getValidateLedgerAccount(selectedCompany: CompanyDataDtoModel, input: string): Observable<SapInfoModel[]> {
    if (selectedCompany?.shortName && input) {
      return this.http.get<SapInfoModel[]>(`${this.base}/validate/ledgerAccount/${selectedCompany.shortName}/${input}`);
    } else {
      return of([]);
    }
  }

  getValidateCreditor(
    selectedCompany: CompanyDataDtoModel,
    input: string,
    formId: string,
  ): Observable<CreditorInfoModel[]> {
    return this.http.get<CreditorInfoModel[]>(`${this.base}/validate/creditor/${selectedCompany.shortName}/${input
    || undefined}/${formId}`);
  }

  getValidateDebitor(selectedCompany: CompanyDataDtoModel, input: string): Observable<CreditorInfoModel[]> {
    return this.http.get<CreditorInfoModel[]>(`${this.base}/validate/debitor/${selectedCompany.shortName}/${input || undefined}`);
  }

  postFormLock(formPublicId: string) {
    return this.http.post<{ userId: string }>(`${this.base}/lock/${formPublicId}`, {});
  }

  getFormLock(formPublicId: string) {
    return this.http.get<{ userId: string }>(`${this.base}/lock/${formPublicId}`);
  }

  getDeputyOf = (userId: string): Observable<boolean> => this.http.get<boolean>(`${this.base}/deputy/of/${userId}`);

  postComment = (id: string, comment: string) => this.http.post<any>(`${this.base}/process/comment/${id}`, comment);

  postSearchCreditor
  (
    selectedCompany: CompanyDataDtoModel,
    searchData: Partial<CreditorInfoModel>,
    formId: string
  ) {
    return this.http.post<CreditorInfoModel[]>(
      `${this.base}/search/creditor/${selectedCompany.shortName}/${formId}`,
      searchData
    );
  }

  postSearchDebitor(selectedCompany: CompanyDataDtoModel, searchData: Partial<CreditorInfoModel>) {
    return this.http.post<CreditorInfoModel[]>(`${this.base}/search/debitor/${selectedCompany.shortName}`, searchData);
  }

  postSearchAccounting(selectedCompany: CompanyDataDtoModel, searchData: { searchTerm: string, type: string }) {
    return this.http.post<SapInfoModel[]>(`${this.base}/search/accounting/${selectedCompany.shortName}`, searchData);
  }

  postSearchPurchasingGroups(selectedCompany: CompanyDataDtoModel, searchData = '') {
    return this.http.post<SapInfoModel[]>(`${this.base}/search/purchasegroup/${selectedCompany?.shortName}`, { searchData });
  }

  postSearchProductGroups(selectedCompany: CompanyDataDtoModel, searchData = ' ') {
    return this.http.post<ProductGroupInfoModel[]>(`${this.base}/search/productgroup/${selectedCompany?.shortName}`, searchData);
  }

  getLocationInfo() {
    return this.http.get<LocationInfo[]>(`${this.base}/catering/locations`);
  }

  getRoomsByLocation(id: any) {
    return this.http.get<Room[]>(`${this.base}/catering/rooms/${id}`);
  }

  postRooms(locationId: string, rooms: Room[]) {
    return this.http.post<Room[]>(`${this.base}/catering/rooms/${locationId}`, rooms);
  }

  getArticlesByLocation(id: any) {
    return this.http.get<Room[]>(`${this.base}/catering/articles/${id}`);
  }

  postArticle(locationId: string, articles: Article[]) {
    return this.http.post<Article[]>(`${this.base}/catering/articles/${locationId}`, articles);
  }

  getCateringOrderData(selectedCompany: string) {
    return this.http.get<CateringOrderData[]>(`${this.base}/catering/order/data/${selectedCompany}`);
  }

  postCateringOrderData(data: CateringOrderData[], selectedCompany: string) {
    return this.http.post<CateringOrderData[]>(`${this.base}/catering/order/data/${selectedCompany}`, data);
  }

  postOcrFile(file: string, formIdentifier: string, selectedCompanyShortName: string) {
    return this.http.post<String>(`${this.base}/ocr/${selectedCompanyShortName}/${formIdentifier}`, file);
  }

  getFaqItems() {
    return this.http.get<FaqElementModel[]>(`${this.base}/faq`);
  }

  getDashboardStateCount(from: string | undefined, to: string | undefined) {
    const urlParamTo = this.formatURLParam(to.split('T')[0], 'to');
    const urlParamFrom = this.formatURLParam(from.split('T')[0], 'from');
    return this.http.get<DashboardStateCountType>(`${this.base}/dashboard/statecount?${urlParamFrom}${urlParamTo}`);
  }

  getDashboardThroughput(from: string | undefined, to: string | undefined) {
    const urlParamTo = this.formatURLParam(to.split('T')[0], 'to');
    const urlParamFrom = this.formatURLParam(from.split('T')[0], 'from');
    return this.http.get<DashboardThroughputType>(`${this.base}/dashboard/throughput?${urlParamFrom}${urlParamTo}`);
  }

  getDashboardAmounts(from: string | undefined, to: string | undefined) {
    const urlParamTo = this.formatURLParam(to.split('T')[0], 'to');
    const urlParamFrom = this.formatURLParam(from.split('T')[0], 'from');
    return this.http.get<CumulativeDashboardData[]>(`${this.base}/dashboard/amounts?${urlParamFrom}${urlParamTo}`);

  }

  getProcessQueueData() {
    return this.http.get<ProcessQueueModel[]>(`${this.base}/process/queue`);
  }

  postResetProcessQueue(ids: number[]) {
    return this.http.post(`${this.base}/process/queue/reset`, ids);
  }

  deleteProcessQueue(id: number) {
    return this.http.delete(`${this.base}/process/queue/reset/${id}`);
  }

  postFaqItems(value: FaqElementModel[]) {
    return this.http.post<FaqElementModel[]>(`${this.base}/faq`, value);
  }

  downloadMassDataTemplate(formIdentifier: string, companyIdentifier: string, count: number) {
    const url = `${this.base}/public/mass/data/${formIdentifier}/${companyIdentifier}/${count}`;
    return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(map(ApiService.downloadResponse));
  }

  uploadMassDataTemplate(formIdentifier: string, companyIdentifier: string, file: FileUploadModel) {
    const url = `${this.base}/public/mass/data/${formIdentifier}/${companyIdentifier}`;
    return this.http.post(url, file);
  }

  postServiceCenterData(value: NetRegionModel<AccountFormMappingDtoModel>[]): Observable<NetRegionModel<AccountFormMappingDtoModel>[]> {
    return this.http.post<NetRegionModel<AccountFormMappingDtoModel>[]>(`${this.base}/servicecenter`, value);
  }

  getServiceCenterData(): Observable<NetRegionModel<AccountFormMappingDtoModel>[]> {
    return this.http.get<NetRegionModel<AccountFormMappingDtoModel>[]>(`${this.base}/servicecenter`);
  }

  getServiceCenterDataByCompany(company: CompanyDataDtoModel): Observable<NetRegionModel<AccountFormMappingDtoModel>[]> {
    return this.http.get<NetRegionModel<AccountFormMappingDtoModel>[]>(`${this.base}/servicecenter/${company.id}`);
  }

  getServiceCenterDataByCompanyAndForm(
    company: CompanyDataDtoModel,
    formIdentifier: string,
  ) {
    return this.http.get<NetRegionModel<AccountFormMappingDtoModel>[]>(`${this.base}/servicecenter/${company.id}/${formIdentifier}`);
  }

  getCategoryInfos() {
    return this.http.get<CategoryInfoModel[]>(`${this.base}/form/categoryinfos`);
  }

  postCategoryInfos(data: CategoryInfoModel[]) {
    return this.http.post<CategoryInfoModel[]>(`${this.base}/form/categoryinfos`, data);
  }

  loadVoucherData(
    { year, referenceNumber }: { year: number, referenceNumber: string },
    { companyId, backendClient }: { companyId: string, backendClient: string },
  ) {
    return this.http.get<VoucherData<VoucherEntry>>(`${this.base}/voucher/${companyId}/${backendClient}/${year}/${referenceNumber}`);
  }

  loadOrderData(
    { orderNumber, orderPosition }: { orderNumber: string, orderPosition: string },
    { companyId, backendClient }: { companyId: string, backendClient: string },
  ) {
    return this.http.get<FormK3OrderData>(`${this.base}/order/${companyId}/${backendClient}/${orderNumber}/${orderPosition}`);
  }

  getFuvData(fuvNumber: string, companyShort: string) {
    return this.http.get<FuvDataResponseModel>(`${this.base}/fuv/${fuvNumber}/${companyShort}`);
  }
}
